(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:PhaseRecoveryMatchDates
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('PhaseRecoveryMatchDates', PhaseRecoveryMatchDates);

  function PhaseRecoveryMatchDates($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/phases/:id/recoveryMatchDates', {id: '@_id'});
  }
}());
